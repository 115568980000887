export const customTotal = (from, to, size) => (
    <span className='react-bootstrap-table-pagination-total'>
        Mostrando resultados de {from} a {to}
    </span>
);

export const TABLE_PAGINATION = {
    remote: { pagination: true },
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    prePageText: <img src='/images/icons/chevron-prev.svg' alt='' />,
    nextPageText: <img src='/images/icons/chevron.svg' alt='' />,
    hideSizePerPage: true,
    showTotal: true,
    sizePerPage: 5,
    paginationTotalRenderer: customTotal,
};

export const LANGUAGE_OPTIONS = [
    {
        label: 'Spanish',
        countryTitle: 'Spain',
        value: 'ES',
        icon: '/images/icons/Spain-flag.svg',
        squareIcon: '/images/icons/Spain-flag-square.svg',
    },
    {
        label: 'English',
        countryTitle: 'United Kingdom',
        value: 'EN',
        icon: '/images/icons/United-Kingdom-flag.svg',
        squareIcon: '/images/icons/United-Kingdom-flag-square.svg',
    },
    // {
    //     label: 'German',
    //     countryTitle: 'Germany',
    //     value: 'DE',
    //     icon: '/images/icons/Germany-flag.svg',
    //     squareIcon: '/images/icons/Germany-flag-square.svg',
    // },
];
