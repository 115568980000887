import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import { getTextAnimations } from '../../../api/adapters/text-animation';
import GIF from '../../../assets/images/Ui.gif';

function LeftTextAnimation({ GifShow = false }) {
    const { t, i18n } = useTranslation();
    const [gifDuration, setGifDuration] = useState(null);
    const [isGif, setIsGif] = useState(true);

    const {
        data: textAnimations,
        isLoading: isTextAnimationsLoading,
        isError,
    } = useQuery({
        queryKey: ['text-animation', i18n.language],
        queryFn: () =>
            getTextAnimations(i18n.language?.split('-')[0]).then((res) => {
                const formattedArray = [];

                res.data?.aAnimations.forEach((item) => {
                    formattedArray.push(item, 1000);
                });
                return formattedArray;
            }),
        cacheTime: 0,
    });

    useEffect(() => {
        fetch(GIF)
            .then((res) => res.arrayBuffer())
            .then((ab) => isGifAnimated(new Uint8Array(ab)))
            .then();

        /** @param {Uint8Array} uint8 */
        function isGifAnimated(uint8) {
            let duration = 0;
            for (let i = 0, len = uint8.length; i < len; i++) {
                if (
                    uint8[i] == 0x21 &&
                    uint8[i + 1] == 0xf9 &&
                    uint8[i + 2] == 0x04 &&
                    uint8[i + 7] == 0x00
                ) {
                    const delay = (uint8[i + 5] << 8) | (uint8[i + 4] & 0xff);
                    duration += delay < 2 ? 10 : delay;
                }
            }
            return setGifDuration((duration / 100) * 1000 - 200);
        }

        if (gifDuration) {
            setTimeout(() => {
                setIsGif(false);
            }, gifDuration);
        }
    }, [gifDuration]);

    return (
        <div className='left-animation-screen'>
            <div>
                <img
                    className='top-vector-bg'
                    src='/images/top-left.svg'
                    alt=''
                />
                <div className='logo'>
                    <Link to={'/sign-in'}>
                        <img src={'/images/logo-with-title.svg'} alt='' />
                    </Link>
                </div>
                <div className='loop-content'>
                    {isGif || GifShow ? (
                        <img
                            style={{ maxWidth: '300px', margin: '0 auto' }}
                            src={GIF}
                            alt=''
                        />
                    ) : (
                        <div className='text-animation'>
                            <h1 className='text-heading'>
                                {t('have_a_Dialogue')}
                            </h1>
                            <h1 className='animation'>
                                {!isTextAnimationsLoading && !isError ? (
                                    <TypeAnimation
                                        sequence={textAnimations}
                                        repeat={Infinity}
                                        omitDeletionAnimation={true}
                                    />
                                ) : null}
                            </h1>
                        </div>
                    )}
                </div>
            </div>

            <ul className='public-naves'>
                <li>
                    <Link
                        to={'/privacy-policy'}
                        // target='_blank'
                        rel='noopener noreferrer'
                    >
                        {t('footer_links.privacy_policy')}
                    </Link>
                </li>
                <li>
                    <Link
                        to='/legal-notice'
                        // target='_blank'
                        rel='noopener noreferrer'
                    >
                        {t('footer_links.terms_conditions')}
                    </Link>
                </li>
                <li>
                    <Link
                        to='/cookies'
                        // target='_blank'
                        rel='noopener noreferrer'
                    >
                        {t('footer_links.cookies_title')}
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default LeftTextAnimation;
